/* footerComponent.css */

footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #181818d7;
    padding: 15px;
}

.social-media-links {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
}

.social-media-links a {
    text-decoration: none;
    margin-right: 10px;
    .icon-ig {
        color: #fd02cd;
    }
    .icon-fb {
        color: #3b5998;
    }
}

.trademark {
    text-align: center;
}

.social-media-links p {
    margin: 10px;
    font-size: 14px;
    color: #c4c2c2;
}