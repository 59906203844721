.about-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  background-color: #3c3c3cf1;
  margin: 20px;
  padding: 20px;
  padding-bottom: 100px;
  border: 1px solid #000;
  border-radius: 10px;
}

.about-heading {
  font-size: 2em;
  color: #fff;
  margin-bottom: 50px;
}

p {
  font-size: 18px;
  color: #fff;
}
