.App {
  text-align: center;
  background-image: url("assets/images/dcv-cover.jpg");
  background-position: center;
  background-size: inherit;
  height: 100vh;
  overflow: auto;
}

@media (max-width: 768px) {
  .App {
    background-image: url("assets/images/dcv-cover-mobile.jpg");
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

h1 {
  color: #eaa930;
}
