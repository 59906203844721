.event-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 20px 0;
  padding: 20px;
  padding-bottom: 100px;
  border: 1px solid #000;
  border-radius: 10px;
  background-color: #3c3c3c72;
  min-height: 50vh;
}

.event-image {
  max-width: 100%;
  height: 300px;
  object-fit: contain;
  border-radius: 10px;
}

.event-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  margin: 10px 0;
  padding: 10px;
  border-style: outset;
  border-radius: 5px;
  border-color: #000;
  background-color: #3c3c3cbd;
  flex-wrap: wrap;
  justify-content: space-between;
}

.event-date {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fbca3f;
}

.event-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fbca3f;
}

.buy-ticket-btn {
  background-color: #fbca3f;
  color: #000;
  padding: 10px;
  border-radius: 5px;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
}