.home-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  height: 20vh;
  margin: 0 0 20px 0;
  padding: 20px;
  h1 {
    font-size: 3em;
    color: #eaa930;
    margin-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .home-body {
    height: 70vh;
  }
}